.logout-card {
  min-width: 10%;
}

.logout-card__heading {
  margin-top: 0;
}

.logout-card__buttons {
  display: flex;
  justify-content: space-between;
}
